import CustomApiError from './CustomError';

export default class PixOrderPaymentError extends CustomApiError {
  toDictionary() {
    return {
      AMOUNT_NOT_AVAILABLE: 'Valor não disponível na conta de origem.',
      AUTH_CODE_NOT_FOUND: 'Código de autorização não encontrado.',
      FINANCIAL_INSTITUTION_NOT_FOUND: 'Instituição bancária não encontrada.',
      PARTNER_PIX_FEE_NOT_FOUND:
        'Taxa de ordem de pagamento do parceiro não encontrada.',
      PAYMENT_ACCOUNT_NOT_FOUND: 'Conta de pagamento não encontrada.',
      PIX_FEE_NOT_FOUND: 'Taxa de ordem de pagamento não encontrada.',
      PIX_KEY_INVALID: 'Chave Pix inválida.',
      PIX_KEY_NOT_FOUND: 'Chave Pix não encontrada.',
      USER_NOT_REGISTERED_IN_PIX: 'Usuário não registrado no Pix.',
      CITY_NOT_FOUND: 'Cidade não encontrada.',
      REFUND_INVALID_FOR_DEBIT: 'Não é permitido reembolsar uma ordem de débito.',
      TOTAL_AMOUNT_REFUNDED: 'O valor total da ordem pix já foi devolvido.',
      REFUND_VALUE_INVALID: 'O valor informado excede o valor da ordem pix.',
    };
  }
}
